import React from "react";
import logo from "./img/u42logo.png";
import { Grid, Header, Image, Segment } from "semantic-ui-react";

function ForgetPwdStep2() {
  const msg = "Please click the link in the email you will receive to complete the password reset process.";

  return (
    <Grid className="u42-full-width-center">
      <Grid.Column className="u42-constrained-width">
        <Header as="h2" textAlign="center">
          <Image src={logo} />
          <span>TAXII Feed Password Reset</span>
        </Header>
        <Segment textAlign="left">{msg}</Segment>
      </Grid.Column>
    </Grid>
  );
}

export default ForgetPwdStep2;
