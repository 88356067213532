import React from "react";
import playbook from "./img/playbook.png";
import arrow from "./img/arrow.png";
import taxii from "./img/taxii.png";
import { Divider, Image, Segment } from "semantic-ui-react";

function Home() {
  const aboutUsUrl = "https://unit42.paloaltonetworks.com/about-unit-42/";
  const pbUrl = "https://unit42.paloaltonetworks.com/atoms/";
  const taxiiUrl = "https://oasis-open.github.io/cti-documentation/";
  const clientUrl = "https://github.com/oasis-open/cti-taxii-client";
  const serverUrl = "https://github.com/oasis-open/cti-taxii-server";
  const oasisUrl = "https://www.oasis-open.org/resources/open-repositories/";
  const xsoarUrl = "https://www.paloaltonetworks.com/cortex/threat-intel-management";

  return (
    <Segment key="m" className="u42-home" padded>
      <Segment>
        <Image src={playbook} size="small" />
        <Image src={arrow} size="small" />
        <Image src={taxii} size="small" />
      </Segment>
      <Divider />
      <h1>Welcome to the Unit 42 TAXII feed portal.</h1>
      <Divider />
      <p>
        <a href={aboutUsUrl}>Unit 42</a> provides a feed of IOCs published in our{" "}
        <a href={pbUrl}>Actionable Threat Objects & Mitigations (ATOM) Viewer</a>. If this is your first visit, you can
        use the sign up link above to register to receive the feed. If you have already registered, you can login above
        to see the required feed configuration and to create your API keys for this service.
      </p>
      <Divider />
      <p>
        This is a <a href={taxiiUrl}>TAXII 2.0</a> feed. The feed is provided using the{" "}
        <a href={serverUrl}>Medallion</a> TAXII server from <a href={oasisUrl}>OASIS TC Open Repositories</a>. Standard
        TAXII clients, such as the <a href={clientUrl}>cti-taxii-client</a>, can be used to consume the feed.
      </p>
      <Divider />
      <p>
        <a href={xsoarUrl}>Cortex XSOAR - Threat Intel Management</a> also has a built-in prototype for consuming the
        Unit42 TAXII feed.
      </p>
    </Segment>
  );
}

export default Home;
