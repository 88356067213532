import React, { useContext, useState } from "react";
import logo from "./img/u42logo.png";
import { Button, Divider, Form, Grid, Header, Image, Label, Segment } from "semantic-ui-react";
import { RemoteApiContext } from "./RemoteApiCtx";

function ForgetPwdStep3(props) {
  const api = useContext(RemoteApiContext);
  const params = new URLSearchParams(props.location.search);
  const [message, setMessage] = useState("Enter your new password");
  const [_id] = useState(params.get("_id") || "");
  const [token] = useState(params.get("token") || "");
  const [password, setPassword] = useState("");

  function onChangePassword(event) {
    setPassword(event.target.value);
  }

  function submit() {
    api
      .fetch("PATCH", `users/${_id}`, { _id, status: "reset", password: password.trim(), token })
      .then((data) => {
        if (data.status === "200") {
          console.log(data);
          const s_id = encodeURIComponent(_id);
          const ssecret = encodeURIComponent(data.secret);
          const sotpauth = encodeURIComponent(data.otpauth);
          const step4 = `/signup-step4?_id=${s_id}&secret=${ssecret}&otpauth=${sotpauth}`;
          props.history.replace(step4);
        } else {
          setMessage(JSON.stringify(data));
        }
      })
      .catch((rsp) => {
        console.log(rsp);
        setMessage(`${rsp}`);
      });
  }

  return (
    <Grid className="u42-full-width-center">
      <Grid.Column className="u42-constrained-width">
        <Header as="h2" textAlign="center">
          <Image src={logo} />
          <span>TAXII Feed Password Reset</span>
        </Header>
        <Segment textAlign="left">{message}</Segment>
        <Divider />
        <Form size="large">
          <Segment stacked>
            <div className="field">
              <Label>{_id}</Label>
            </div>
            <div className="field">
              <Label>{token}</Label>
            </div>
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="New Password"
              id="password"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={onChangePassword}
            />
            <Button fluid size="large" onClick={submit}>
              <span>Next</span>
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

export default ForgetPwdStep3;
