import React, { useContext, useState } from "react";
import logo from "./img/u42logo.png";
import { Divider, Form, Grid, Header, Image, Segment } from "semantic-ui-react";
import { RemoteApiContext } from "./RemoteApiCtx";

// import {UserContext} from "./UserCtx";

function ForgetPwdStep1(props) {
  const api = useContext(RemoteApiContext);
  // const {1: setUser} = useContext(UserContext);
  const [status, setStatus] = useState("");
  const [_id, set_id] = useState("");
  // const [password, setPassword] = useState("");
  // const [totp, setTotp] = useState("");
  const step2 = "/fp-step2";

  function onChange_id(event) {
    set_id(event.target.value);
  }

  function submit() {
    api
      .fetch("PATCH", `users/${_id.trim()}`, { status: "reset" })
      .then((data) => {
        if (data.status === "200") {
          props.history.replace(step2);
        } else {
          setStatus(JSON.stringify(data));
        }
      })
      .catch((rsp) => {
        setStatus(`${rsp}`);
      });
  }

  const msg = status || "";

  return (
    <Grid className="u42-full-width-center">
      <Grid.Column className="u42-constrained-width">
        <Header as="h2" textAlign="center">
          <Image src={logo} />
          <span>TAXII Feed Password Reset</span>
        </Header>
        <Segment textAlign="left">{msg}</Segment>
        <Divider />
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              placeholder="E-mail address"
              id="_id"
              type="text"
              pattern=".+@.+[.].+"
              autoComplete="email"
              value={_id}
              onChange={onChange_id}
            />
            <Form.Button fluid size="large" onClick={submit}>
              <span>Next</span>
            </Form.Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

export default ForgetPwdStep1;
