import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Icon, Modal, Table } from "semantic-ui-react";
import copy from "copy-to-clipboard";
import update from "immutability-helper";
import { UserContext } from "./UserCtx";
import { fmtDate } from "./Utils";
import { RemoteApiContext } from "./RemoteApiCtx";

function Users(props) {
  const api = useContext(RemoteApiContext);
  const [user, setUser] = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [editing, setEditing] = useState({ open: false });
  const key = "_id";
  const rows = users;

  function refresh() {
    api
      .fetch("GET", "users")
      .then((data) => {
        if (data.status === "200") {
          setUsers(data.users);
        } else if (data.status === "401") {
          setUser(update(user, { loggedIn: { $set: false } }));
          props.history.push("/");
        } else {
          console.error(data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getXid(value) {
    // noinspection JSUnresolvedVariable
    return value.xid;
  }

  function add(/*event, data*/) {
    setEditing({
      open: true,
      _id: "",
      contactName: "",
      companyName: "",
      password: "",
      roles: false,
      status: "pending",
    });
  }

  function beginEdit(event, data) {
    const xid = getXid(data);
    const row = rows.find((v) => v[key] === xid);

    if (row !== undefined) {
      setEditing({ ...row, open: true, xid, password: "", roles: row.roles.includes("admin") });
    }
  }

  function onChangeValue(event, data) {
    setEditing({ ...editing, [data.id]: data.value });
  }

  function onChangeToggle(event, data) {
    setEditing({ ...editing, [data.id]: data.checked });
  }

  function endEdit(/*event, data*/) {
    const xid = getXid(editing);
    const idx = rows.findIndex((v) => v[key] === xid);
    if (idx >= 0) {
      const params = {
        contactName: editing.contactName.trim(),
        companyName: editing.companyName.trim(),
        roles: editing.roles ? ["admin"] : [],
        status: editing.status.trim(),
      };
      if (editing.password.trim().length > 0) {
        params.password = editing.password.trim();
      }
      api
        .fetch("PATCH", `users/${xid}`, params)
        .then((data) => {
          if (data.status === "200") {
            setUsers(update(rows, { [idx]: { $set: data.user } }));
            if (user._id === data.user._id) {
              setUser(update(data.user, { loggedIn: { $set: true } }));
            }
          } else {
            console.error(data);
          }
          setEditing({ open: false });
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (xid === undefined) {
      const params = {
        contactName: editing.contactName.trim(),
        companyName: editing.companyName.trim(),
        roles: editing.roles ? ["admin"] : [],
        status: editing.status.trim(),
      };
      api
        .fetch("POST", `users/${xid}`, params)
        .then((data) => {
          if (data.status === "200") {
            setUsers(update(rows, { $splice: [[0, 0, data.user]] }));
            if (user._id === data.user._id) {
              setUser(update(data.user, { loggedIn: { $set: true } }));
            }
          } else {
            console.error(data);
          }
          setEditing({ open: false });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setEditing({ open: false });
    }
  }

  function cancelEdit(/*event, data*/) {
    setEditing({ open: false });
  }

  function copyToClipboard(event, data) {
    copy(getXid(data));
  }

  function remove(event, data) {
    const xid = getXid(data);
    const idx = rows.findIndex((v) => v[key] === xid);
    if (idx >= 0) {
      api
        .fetch("DELETE", `users/${xid}`)
        .then((data) => {
          if (data.status === "200") {
            const newRows = update(rows, { $splice: [[idx, 1]] });
            setUsers(newRows);
          } else {
            console.error(data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  useEffect(refresh, [api, props.history, setUser, setUsers, user]);

  if (!user.loggedIn || !user.roles.includes("admin")) {
    props.history.push("/");
    return <div />;
  }

  return (
    <div className="u42-table-container">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>E-mail</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Created</Table.HeaderCell>
            <Table.HeaderCell>Updated</Table.HeaderCell>
            <Table.HeaderCell>Last Login</Table.HeaderCell>
            <Table.HeaderCell>Login From</Table.HeaderCell>
            <Table.HeaderCell>API Keys</Table.HeaderCell>
            <Table.HeaderCell>Roles</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell className="actions-header" collapsing>
              <Button icon size="small" onClick={refresh} title="Refresh">
                <Icon name="refresh" />
              </Button>
              <Button icon size="small" onClick={add} title="Add new user...">
                <Icon name="add" />
                <Icon name="user" />
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rows.map((v, i) => (
            <Table.Row key={`${i}`}>
              <Table.Cell>{v._id}</Table.Cell>
              <Table.Cell>{v.contactName}</Table.Cell>
              <Table.Cell>{v.companyName}</Table.Cell>
              <Table.Cell>{fmtDate(v.created)}</Table.Cell>
              <Table.Cell>{fmtDate(v.updated)}</Table.Cell>
              <Table.Cell>{fmtDate(v.lastUsedWhen)}</Table.Cell>
              <Table.Cell>{v.lastUsedFrom}</Table.Cell>
              <Table.Cell>{v.apikeys.length}</Table.Cell>
              <Table.Cell>{v.roles.join(", ")}</Table.Cell>
              <Table.Cell>{v.status}</Table.Cell>
              <Table.Cell collapsing>
                <Button
                  xid={v[key]}
                  icon="copy outline"
                  size="small"
                  onClick={copyToClipboard}
                  title="Copy to clipboard"
                />
                <Button xid={v[key]} icon="edit outline" size="small" onClick={beginEdit} title={`Edit ${v._id}`} />
                <Button
                  xid={v[key]}
                  icon="trash alternate outline"
                  size="small"
                  onClick={remove}
                  title={`Delete ${v._id}`}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal open={editing.open} onClose={cancelEdit}>
        <Modal.Header>Properties for {editing.xid || "new user"}...</Modal.Header>
        <Modal.Content>
          <Form size="large">
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              placeholder="E-mail address"
              id="_id"
              type="text"
              pattern=".+@.+[.].+"
              autoComplete="email"
              value={editing._id}
              onChange={onChangeValue}
              disabled={!!editing.xid}
            />
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Contact Name"
              id="contactName"
              type="text"
              autoComplete="name"
              value={editing.contactName}
              onChange={onChangeValue}
            />
            <Form.Input
              fluid
              icon="building"
              iconPosition="left"
              placeholder="Company"
              id="companyName"
              type="text"
              autoComplete="organization"
              value={editing.companyName}
              onChange={onChangeValue}
            />
            {editing.xid ? (
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="New Password"
                id="password"
                type="password"
                autoComplete="new-password"
                value={editing.password}
                onChange={onChangeValue}
              />
            ) : null}
            <Form.Checkbox toggle label="Admin?" id="roles" checked={editing.roles} onChange={onChangeToggle} />
            <Form.Input
              fluid
              icon="bolt"
              iconPosition="left"
              placeholder="Status"
              id="status"
              type="text"
              value={editing.status}
              onChange={onChangeValue}
            />
            <Modal.Actions>
              <Button primary onClick={endEdit}>
                Save
              </Button>
              <Button type="button" onClick={cancelEdit}>
                Cancel
              </Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default Users;
