import React, { useContext, useEffect, useState } from "react";
import logo from "./img/u42logo.png";
import { Grid, Header, Image, Segment, Dimmer, Loader } from "semantic-ui-react";
import { RemoteApiContext } from "./RemoteApiCtx";

function EmailOptout(props) {
  const api = useContext(RemoteApiContext);
  const params = new URLSearchParams(props.location.search);
  const _id = params.get("_id") || "";
  const token = params.get("token") || "";
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    api
      .fetch("PATCH", `users/${_id}`, { status: "suspended", token })
      .then((data) => {
        if (data.status === "200") {
          setMsg(
            "Your email address has been successfully suspended. If you wish to re-enable system access, please contact "
          );
        } else {
          setMsg(
            "Something went wrong suspending your account. If you wish to suspend your account access, please contact "
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [api, setMsg, setLoading, _id, token]);

  return (
    <Grid className="u42-full-width-center">
      <Grid.Column className="u42-constrained-width">
        <Header as="h2" textAlign="center">
          <Image src={logo} />
          <span>TAXII Feed Email Suspended</span>
        </Header>
        <Segment textAlign="left">
          <Dimmer active={loading}>
            <Loader />{" "}
          </Dimmer>
          {msg}
          <a href="mailto:support@unit42.org">support@unit42.org</a>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

export default EmailOptout;
