import React from "react";
import logo from "./img/u42logo.png";
import { Divider, Grid, Header, Image, Message, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";

function SignupStep2() {
  const msg = "Please click the link in the email you will receive to complete the signup process.";

  return (
    <Grid className="u42-full-width-center">
      <Grid.Column className="u42-constrained-width">
        <Header as="h2" textAlign="center">
          <Image src={logo} />
          <span>TAXII Feed Sign Up</span>
        </Header>
        <Segment textAlign="left">{msg}</Segment>
        <Divider />
        <Message>
          Already signed up? <Link to="/login">Login</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}

export default SignupStep2;
