import React, { createContext } from "react";

const RemoteApiContext = createContext([{}, () => {}]);

function encode(v) {
  return typeof v === "object" ? JSON.stringify(v) : v;
}

function combine(a, b) {
  const c = a.endsWith("/") ? `${a}${b}` : `${a}/${b}`;

  return c.endsWith("/") ? c : `${c}/`;
}

function RemoteApiProvider(props) {
  const url = combine(process.env.REACT_APP_API_URL || "http://localhost:3001", "apiv1");
  const opts = url.startsWith("/")
    ? { mode: "same-origin", credentials: "same-origin" }
    : { mode: "cors", credentials: "include" };
  const remoteApi = {
    fetch(method, path, params) {
      const options = { method, ...opts };

      if (params) {
        const body = new URLSearchParams();
        Object.keys(params).forEach((k) => body.append(k, encode(params[k])));
        options.body = body;
      }

      return fetch(`${url}${path}`, options).then((rsp) => rsp.json());
    },
  };

  return <RemoteApiContext.Provider value={remoteApi}>{props.children}</RemoteApiContext.Provider>;
}

export { RemoteApiContext, RemoteApiProvider };
