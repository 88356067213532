import React, { useContext, useState } from "react";
import logo from "./img/u42logo.png";
import { Divider, Form, Grid, Header, Image, Message, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { RemoteApiContext } from "./RemoteApiCtx";
import { UserContext } from "./UserCtx";
import update from "immutability-helper";

function Login(props) {
  const api = useContext(RemoteApiContext);
  const { 1: setUser } = useContext(UserContext);
  const [status, setStatus] = useState("");
  const [_id, set_id] = useState("");
  const [password, setPassword] = useState("");

  function onChange_id(event) {
    set_id(event.target.value);
  }

  function onChangePassword(event) {
    setPassword(event.target.value);
  }

  function submit() {
    api
      .fetch("POST", "sessions", { _id: _id.trim(), password: password.trim() })
      .then((data) => {
        if (data.status === "200") {
          setUser(update(data.user, { loggedIn: { $set: true } }));
          props.history.replace("/apikeys");
        } else {
          setStatus(JSON.stringify(data));
        }
      })
      .catch((err) => {
        setStatus(`${err}`);
      });
  }

  const msg = status || "";

  return (
    <Grid className="u42-full-width-center">
      <Grid.Column className="u42-constrained-width">
        <Header as="h2" textAlign="center">
          <Image src={logo} />
          <span>TAXII Feed</span>
        </Header>
        <Segment textAlign="left">{msg}</Segment>
        <Divider />
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              placeholder="E-mail address"
              id="_id"
              type="text"
              pattern=".+@.+[.].+"
              autoComplete="email"
              value={_id}
              onChange={onChange_id}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              id="password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={onChangePassword}
            />
            <Form.Button fluid size="large" onClick={submit}>
              <span>Login</span>
            </Form.Button>
          </Segment>
        </Form>
        <Message>
          New to us? <Link to="/signup-step1">Sign Up</Link>
        </Message>
        <Message>
          Forgot password? <Link to="/fp-step1">Reset Password</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}

export default Login;
