import React, { useContext, useState } from "react";
import { Button, Form, Header, Icon, Modal, Segment, Table } from "semantic-ui-react";
import update from "immutability-helper";
import copy from "copy-to-clipboard";
import { RemoteApiContext } from "./RemoteApiCtx";
import { UserContext } from "./UserCtx";
import { cmpRows, fmtDate } from "./Utils";

function nickname(xid) {
  return xid === undefined ? "*none*" : `${xid.substring(0, 6)}...`;
}

function Apikeys(props) {
  const api = useContext(RemoteApiContext);
  const [user, setUser] = useContext(UserContext);
  const [editing, setEditing] = useState({ open: false });
  const key = "_id";
  const rows = user.apikeys !== undefined ? user.apikeys : [];
  const disabled = rows.length >= 5;

  function refresh() {
    api.fetch("GET", "me").then((data) => {
      if (data.status === "200") {
        setUser(update(data.user, { loggedIn: { $set: true } }));
      } else {
        setUser({ loggedIn: false });
      }
    });
  }

  function getXid(value) {
    // noinspection JSUnresolvedVariable
    return value.xid;
  }

  function add(/*event, data*/) {
    api
      .fetch("PATCH", `users/${user._id}`, { apikeys: { add: true } })
      .then((data) => {
        if (data.status === "200") {
          const newRows = update(rows, { $splice: [[0, 0, data.apikey]] });
          newRows.sort(cmpRows);
          setUser(update(user, { apikeys: { $set: newRows } }));
        } else {
          console.error(data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function beginEdit(event, data) {
    const xid = getXid(data);
    const idx = rows.findIndex((v) => v[key] === xid);
    if (idx >= 0) {
      setEditing({ open: true, xid, desc: rows[idx].desc || "" });
    }
  }

  function onChangeValue(event, data) {
    setEditing({ ...editing, [data.id]: data.value });
  }

  function endEdit(/*event, data*/) {
    const xid = getXid(editing);
    const idx = rows.findIndex((v) => v[key] === xid);
    if (idx >= 0) {
      api
        .fetch("PATCH", `users/${user._id}`, { apikeys: { chg: xid, desc: editing.desc.trim() } })
        .then((data) => {
          if (data.status === "200") {
            const newUser = update(user, { apikeys: { [idx]: { $set: data.apikey } } });
            setUser(newUser);
          } else {
            console.error(data);
          }
          setEditing({ open: false });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setEditing({ open: false });
    }
  }

  function cancelEdit(/*event, data*/) {
    setEditing({ open: false });
  }

  function copyToClipboard(event, data) {
    copy(getXid(data));
  }

  function remove(event, data) {
    const xid = getXid(data);
    const idx = rows.findIndex((v) => v[key] === xid);
    if (idx >= 0) {
      api
        .fetch("PATCH", `users/${user._id}`, { apikeys: { del: xid } })
        .then((data) => {
          if (data.status === "200") {
            const newRows = update(rows, { $splice: [[idx, 1]] });
            setUser(update(user, { apikeys: { $set: newRows } }));
          } else {
            console.error(data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  if (!user.loggedIn) {
    props.history.push("/");
    return <div />;
  }

  return (
    <div className="u42-table-container">
      <Segment>
        <Header>TAXII Information</Header>
        <Table celled /*className="u42-narrow"*/>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Icon name="comments outline" />
                Description
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="linkify" />
                TAXII Discovery Service URL
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="folder outline" />
                TAXII API root
              </Table.HeaderCell>
              <Table.HeaderCell>
                <Icon name="barcode" />
                TAXII Collection ID
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Unit 42 Adversary Playbooks</Table.Cell>
              <Table.Cell>https://stix2.unit42.org/taxii/</Table.Cell>
              <Table.Cell>playbooks</Table.Cell>
              <Table.Cell>5ac266d8-de48-3d6b-83f1-c4e4047d6e44</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Unit 42 Reports</Table.Cell>
              <Table.Cell>https://stix2.unit42.org/taxii/</Table.Cell>
              <Table.Cell>reports</Table.Cell>
              <Table.Cell>ca8bf6bb-4063-3588-887d-1c3e16c634d8</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
      <Segment>
        <Header>API Keys</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>API Key</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Last Used</Table.HeaderCell>
              <Table.HeaderCell>Used From</Table.HeaderCell>
              <Table.HeaderCell className="actions-header" collapsing>
                <Button icon size="small" onClick={refresh} title="Refresh">
                  <Icon name="refresh" />
                </Button>
                <Button icon size="small" disabled={disabled} onClick={add} title="Add API key">
                  <Icon name="add" />
                  <Icon name="key" />
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {rows.map((v, i) => (
              <Table.Row key={`${i}`}>
                <Table.Cell>{nickname(v[key])}</Table.Cell>
                <Table.Cell>{v.desc}</Table.Cell>
                <Table.Cell>{fmtDate(v.created)}</Table.Cell>
                <Table.Cell>{fmtDate(v.lastUsedWhen)}</Table.Cell>
                <Table.Cell>{v.lastUsedFrom}</Table.Cell>
                <Table.Cell collapsing>
                  <Button
                    xid={v[key]}
                    icon="copy outline"
                    size="small"
                    onClick={copyToClipboard}
                    title="Copy to clipboard"
                  />
                  <Button xid={v[key]} icon="edit outline" size="small" onClick={beginEdit} title="Edit description" />
                  <Button
                    xid={v[key]}
                    icon="trash alternate outline"
                    size="small"
                    onClick={remove}
                    title={`Delete ${nickname(v[key])}`}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Modal open={editing.open} onClose={cancelEdit}>
          <Modal.Header>Properties for {nickname(getXid(editing))}</Modal.Header>
          <Modal.Content>
            <Form size="large">
              <Form.Input
                fluid
                icon="info"
                iconPosition="left"
                placeholder="Description"
                id="desc"
                type="text"
                value={editing.desc}
                onChange={onChangeValue}
              />
              <Modal.Actions>
                <Button primary onClick={endEdit}>
                  Save
                </Button>
                <Button type="button" onClick={cancelEdit}>
                  Cancel
                </Button>
              </Modal.Actions>
            </Form>
          </Modal.Content>
        </Modal>
      </Segment>
    </div>
  );
}

export default Apikeys;
