import React, { createContext, useContext, useEffect, useState } from "react";
import update from "immutability-helper";
import { RemoteApiContext } from "./RemoteApiCtx";

const UserContext = createContext([{}, () => {}]);

function UserProvider(props) {
  const api = useContext(RemoteApiContext);
  const state = useState({});

  useEffect(() => {
    if (state[0].loggedIn === undefined) {
      api.fetch("GET", "me").then((data) => {
        if (data.status === "200") {
          state[1](update(data.user, { loggedIn: { $set: true } }));
        } else {
          state[1]({ loggedIn: false });
        }
      });
    }
  });

  return (
    <UserContext.Provider value={state}>
      {state[0].loggedIn === undefined ? <div>Scanning the multi-verse...</div> : props.children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
