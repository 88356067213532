import React from "react";
import ReactDOM from "react-dom";
import "./css/semantic.slate.css";
import "./css/index.css";
import { RemoteApiProvider } from "./RemoteApiCtx";
import { UserProvider } from "./UserCtx";
import App from "./App";

ReactDOM.render(
  <RemoteApiProvider>
    <UserProvider>
      <App />
    </UserProvider>
  </RemoteApiProvider>,
  document.getElementById("root")
);
