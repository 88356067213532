import React, { useContext } from "react";
import { BrowserRouter as Router, Link, NavLink, Route, Switch } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import SignupStep1 from "./SignupStep1";
import SignupStep2 from "./SignupStep2";
import SignupStep3 from "./SignupStep3";
import SignupStep4 from "./SignupStep4";
import Apikeys from "./Apikeys";
import Users from "./Users";
import ForgetPwdStep1 from "./ForgetPwdStep1";
import ForgetPwdStep2 from "./ForgetPwdStep2";
import ForgetPwdStep3 from "./ForgetPwdStep3";
import EmailOptout from "./EmailOptout";
import { Icon, Image, Menu, Segment } from "semantic-ui-react";
import panlogo from "./img/paloaltonetworks.svg";
import u42logo from "./img/u42logo.png";
import { RemoteApiContext } from "./RemoteApiCtx";
import { UserContext } from "./UserCtx";

function App() {
  const api = useContext(RemoteApiContext);
  const [user, setUser] = useContext(UserContext);
  const menubar = [];
  const navlinks = [];

  function logout() {
    api
      .fetch("DELETE", "sessions", {})
      .then((/*data*/) => {
        setUser({ loggedIn: false });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  if (user.loggedIn) {
    menubar.push(
      <Menu.Item key="logout" as={Link} to="/" onClick={logout}>
        Logout
      </Menu.Item>
    );
    if (user.roles.includes("admin")) {
      navlinks.push(
        <NavLink key="users" to="/users">
          <Icon name="user" size="big" />
          Users
        </NavLink>
      );
    }
    navlinks.push(
      <NavLink key="apikeys" to="/apikeys">
        <Icon name="key" size="big" />
        API Keys
      </NavLink>
    );
  } else {
    menubar.push(
      <Menu.Item key="login" as={Link} to="/login">
        Login
      </Menu.Item>
    );
    menubar.push(<div key="d1" className="u42-divider" />);
    menubar.push(
      <Menu.Item key="signup" as={Link} to="/signup-step1">
        Sign Up
      </Menu.Item>
    );
  }

  return (
    <Router>
      <Menu className="u42-topmenu" borderless>
        <Menu.Item />
        <Menu.Item>
          <Image src={panlogo} />
        </Menu.Item>
        <div className="u42-divider" />
        <Menu.Item>
          <Image size="small" src={u42logo} />
        </Menu.Item>
        <Menu.Item header>
          <h1>TAXII Feed</h1>
        </Menu.Item>
        <Menu.Item position="right" />
        <div className="u42-divider" />
        {menubar}
      </Menu>
      <div className="u42-nav-container">
        <div className="u42-nav-bar">
          <NavLink to="/" exact>
            <Icon name="home" size="big" />
            Home
          </NavLink>
          {navlinks}
        </div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/signup-step1" component={SignupStep1} />
          <Route path="/signup-step2" component={SignupStep2} />
          <Route path="/signup-step3" component={SignupStep3} />
          <Route path="/signup-step4" component={SignupStep4} />
          <Route path="/apikeys" component={Apikeys} />
          <Route path="/users" component={Users} />
          <Route path="/fp-step1" component={ForgetPwdStep1} />
          <Route path="/fp-step2" component={ForgetPwdStep2} />
          <Route path="/fp-step3" component={ForgetPwdStep3} />
          <Route path="/optout" component={EmailOptout} />
        </Switch>
      </div>
      <Segment className="u42-footer" padded>
        ©2019-{new Date().getFullYear()} Palo Alto Networks, Inc. All rights reserved.
      </Segment>
    </Router>
  );
}

export default App;
