export function fmtDate(v) {
  if (typeof v === "number") {
    v = new Date(v);
  }
  if (typeof v === "object") {
    v = v.toISOString();
  }
  return typeof v === "string" ? v.substring(0, 19).replace("T", " ") : "Never";
}

function cmpDates(a, b) {
  let r;

  if (a) {
    if (b) {
      const ai = a.getTime();
      const bi = b.getTime();
      r = ai < bi ? 1 : bi < ai ? -1 : 0;
    } else {
      r = -1;
    }
  } else if (b) {
    r = 1;
  } else {
    r = 0;
  }

  return r;
}

export function cmpRows(a, b) {
  let r = cmpDates(a.last_used_when, b.last_used_when);
  if (r === 0) {
    r = a._id.localeCompare(b._id);
  }
  return r;
}
