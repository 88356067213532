import React from "react";
import logo from "./img/u42logo.png";
import { Grid, Header, Image, Message, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";

function SignupStep4() {
  return (
    <Grid className="u42-full-width-center">
      <Grid.Column className="u42-constrained-width">
        <Header as="h2" textAlign="center">
          <Image src={logo} />
          <span>Account Created</span>
        </Header>
        <Segment textAlign="center">Ready to create an API key?</Segment>

        <Message>
          <Link to="/login">Login</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}

export default SignupStep4;
