import React, { useContext, useState } from "react";
import logo from "./img/u42logo.png";
import { Divider, Form, Grid, Header, Image, Message, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ReCaptcha from "react-google-recaptcha";
import { RemoteApiContext } from "./RemoteApiCtx";
import { isLocalDev } from "./configuration";

function SignupStep1(props) {
  const api = useContext(RemoteApiContext);
  const [status, setStatus] = useState("");
  const [_id, set_id] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");
  const step2 = "/signup-step2";

  function onChange_id(event) {
    set_id(event.target.value);
  }

  function onChangeCaptchaToken(value) {
    setCaptchaToken(value);
  }

  function submit() {
    api
      .fetch("POST", "registrations", { _id: _id.trim(), "g-recaptcha-response": captchaToken })
      .then((data) => {
        if (data.status === "200") {
          props.history.replace(step2);
        } else {
          setStatus(JSON.stringify(data));
        }
      })
      .catch((err) => {
        setStatus(`${err}`);
      });
  }

  const msg =
    status ||
    "Please enter your email address below and prove that you are not a robot." +
      " You will then receive an email allowing you to complete the signup process.";

  return (
    <Grid className="u42-full-width-center">
      <Grid.Column className="u42-constrained-width">
        <Header as="h2" textAlign="center">
          <Image src={logo} />
          <span>TAXII Feed Sign Up</span>
        </Header>
        <Segment textAlign="left">{msg}</Segment>
        <Divider />
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              placeholder="E-mail address"
              id="_id"
              type="text"
              pattern=".+@.+[.].+"
              autoComplete="email"
              value={_id}
              onChange={onChange_id}
            />
            {
              // Remove the ReCaptcha component for local development
              isLocalDev ? null : (
                <ReCaptcha
                  className="field recaptcha"
                  size="normal"
                  theme="dark"
                  sitekey="6LcvbJskAAAAAMWw54hPff9DUWNb9beZ77VlwgKC"
                  onChange={onChangeCaptchaToken}
                />
              )
            }
            <Form.Button fluid size="large" onClick={submit}>
              <span>Next</span>
            </Form.Button>
          </Segment>
        </Form>
        <Message>
          Already signed up? <Link to="/login">Login</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}

export default SignupStep1;
