import React, { useContext, useState } from "react";
import logo from "./img/u42logo.png";
import { Button, Divider, Form, Grid, Header, Image, Segment } from "semantic-ui-react";
import { RemoteApiContext } from "./RemoteApiCtx";

function SignupStep3(props) {
  const api = useContext(RemoteApiContext);
  const params = new URLSearchParams(props.location.search);
  const _id = params.get("_id") || "";
  const token = params.get("token") || "";
  const [status, setStatus] = useState("");
  const [contactName, setContactName] = useState(params.get("contactName") || "");
  const [companyName, setCompanyName] = useState(params.get("companyName") || "");
  const [password, setPassword] = useState("");

  function onChangeContactName(event) {
    setContactName(event.target.value);
  }

  function onChangeCompanyName(event) {
    setCompanyName(event.target.value);
  }

  function onChangePassword(event) {
    setPassword(event.target.value);
  }

  function submit() {
    api
      .fetch("POST", "users", {
        _id,
        token,
        contactName: contactName.trim(),
        companyName: companyName.trim(),
        password: password.trim(),
      })
      .then((data) => {
        if (data.status === "200") {
          const step4 = `/signup-step4`;
          props.history.replace(step4);
        } else {
          setStatus(JSON.stringify(data));
        }
      })
      .catch((err) => {
        setStatus(`${err}`);
      });
  }

  const msg = status || "Tell us a little about yourself and set your password.";

  return (
    <Grid className="u42-full-width-center">
      <Grid.Column className="u42-constrained-width">
        <Header as="h2" textAlign="center">
          <Image src={logo} />
          <span>TAXII Feed Sign Up</span>
        </Header>
        <Segment textAlign="left">{msg}</Segment>
        <Divider />
        <Form size="large">
          <Segment stacked>
            {/*<div className="field"><Label>{_id}</Label></div>*/}
            {/*<div className="field"><Label>{token}</Label></div>*/}
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Name"
              id="contactName"
              type="text"
              autoComplete="name"
              value={contactName}
              onChange={onChangeContactName}
            />
            <Form.Input
              fluid
              icon="building"
              iconPosition="left"
              placeholder="Company"
              id="companyName"
              type="text"
              autoComplete="organization"
              value={companyName}
              onChange={onChangeCompanyName}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              id="password"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={onChangePassword}
            />
            <Button fluid size="large" onClick={submit}>
              <span>Next</span>
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

export default SignupStep3;
